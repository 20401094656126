/* eslint-disable @next/next/no-img-element */
import {
  SeasonFragment,
  SeasonLeagueFragment,
  SeasonStatsPlayerFragment,
} from '@amf/shared/types/graphql';
import Link from 'next/link';
import { useTranslate } from '@tolgee/react';
import { getInitials } from '@amf/shared/utils/string';
import Loader from '@amf/shared/components/utils/Loader';
import TeamDress from '@amf/shared/components/team/TeamDress';
import Button from '@amf/shared/components/button/Button';
import { useEffect, useMemo, useState } from 'react';
import { MatchDashboardListLeague } from '@amf/shared/components/dashboard/MatchDashboardList';
import useLeagueStats from '@amf/shared/data/hooks/useLeagueStats';
import classNames from 'classnames';

interface PlayerDashboardGridTypeProps {
  title: string;
  players: SeasonStatsPlayerFragment[];
  landscape?: boolean;
  prefixes: {
    player: string;
  };
}

function PlayerDashboardGridType({
  title,
  players,
  landscape,
  prefixes,
}: PlayerDashboardGridTypeProps) {
  return (
    <div
      className={classNames('PlayerDashboardGrid__type', {
        'PlayerDashboardGrid__type--landscape': landscape,
      })}
    >
      <div className='PlayerDashboardGrid__typePromoted'>
        <h3 className='PlayerDashboardGrid__typeName'>{title}</h3>
        <Link href={`${prefixes.player}/${players[0].player.id}`}>
          <a className='PlayerDashboardGrid__player'>
            {players[0].player.photoUrl ? (
              <img
                className='PlayerDashboardGrid__playerAvatar'
                src={players[0].player.photoUrl}
                alt={players[0].player.fullName}
              />
            ) : (
              <div className='PlayerDashboardGrid__playerAvatar'>
                {getInitials(players[0].player.givenName, players[0].player.familyName)}
              </div>
            )}
            <div className='PlayerDashboardGrid__playerName'>{players[0].player.fullName}</div>
            {players[0].player.currentTeam && (
              <div className='PlayerDashboardGrid__playerTeam'>
                <TeamDress
                  className='PlayerDashboardGrid__playerDress'
                  dress={players[0].player.currentTeam}
                />
                {players[0].player.currentTeam.name}
              </div>
            )}
            <div className='PlayerDashboardGrid__playerCount'>{players[0].count}</div>
          </a>
        </Link>
      </div>
      <table className='PlayerDashboardGrid__table'>
        <tbody>
          {players.slice(1).map(({ id, player, count }) => (
            <tr key={id}>
              <th>
                <Link href={`${prefixes.player}/${player.id}`}>
                  <a>
                    <span>{player.fullName}</span>
                    <small>{player.currentTeam?.name}</small>
                  </a>
                </Link>
              </th>
              <td>{count}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

interface PlayerDashboardGridProps {
  season: SeasonFragment;
  prefixes: {
    player: string;
    stats: string;
  };
  disablePoints?: boolean;
}

function PlayerDashboardGrid({ season, prefixes, disablePoints }: PlayerDashboardGridProps) {
  const { t } = useTranslate();

  const leagues = useMemo<SeasonLeagueFragment[]>(() => {
    return season.leagues.filter(league => league.phases.find(p => p.current));
  }, [season]);

  const [league, setLeague] = useState<SeasonLeagueFragment | undefined>();
  useEffect(() => setLeague(leagues[0]), [leagues]);

  const { stats, loading } = useLeagueStats(season.id, league?.id ?? null);
  const enabledSections = useMemo(() => {
    let sections = 0;

    if (stats?.goalPlayers.length > 0) sections += 1;
    if (stats?.assistPlayers.length > 0) sections += 1;
    if (stats?.pointPlayers.length > 0 && !disablePoints) sections += 1;

    return sections;
  }, [disablePoints, stats]);

  if ((!loading && !stats) || !league) {
    return null;
  }

  return (
    <div className='PlayerDashboardGrid'>
      <div className='PlayerDashboardGrid__header'>
        <h2 className='h1 PlayerDashboardGrid__title'>{t('season.stats.players')}</h2>
        <div className='PlayerDashboardGrid__actions'>
          <MatchDashboardListLeague leagues={leagues} selected={league} onChange={setLeague} />
        </div>
      </div>
      {loading && <Loader size='small'>{t('loading')}</Loader>}
      {!loading && stats && (
        <>
          <div className='PlayerDashboardGrid__content'>
            {stats.goalPlayers.length > 0 && (
              <PlayerDashboardGridType
                title={t('season.stats.players.goals')}
                players={stats.goalPlayers}
                prefixes={prefixes}
                landscape={enabledSections === 1}
              />
            )}
            {stats.assistPlayers.length > 0 && (
              <PlayerDashboardGridType
                title={t('season.stats.players.assists')}
                players={stats.assistPlayers}
                prefixes={prefixes}
                landscape={enabledSections === 1}
              />
            )}
            {stats.pointPlayers.length > 0 && !disablePoints && (
              <PlayerDashboardGridType
                title={t('season.stats.players.points')}
                players={stats.pointPlayers}
                prefixes={prefixes}
                landscape={enabledSections === 1}
              />
            )}
          </div>
          <div className='MatchDashboardList__more'>
            <Link href={prefixes.stats}>
              <a>
                <Button variant='dark-outline'>{t('season.stats.more')}</Button>
              </a>
            </Link>
          </div>
        </>
      )}
    </div>
  );
}

export default PlayerDashboardGrid;
