/* eslint-disable @next/next/no-img-element */
import Link from 'next/link';
import fromUnixTime from 'date-fns/fromUnixTime';
import { truncate } from '@amf/shared/utils/string';
import { NewsListItemFragment } from '@amf/shared/types/graphql';
import classNames from 'classnames';
import { formatDate } from '@amf/shared/utils/date';

interface NewsListItemProps {
  news: NewsListItemFragment;
  prefix: string;
  contentClassName?: string;
  titleClassName?: string;
  timeClassName?: string;
  perexClassName?: string;
}

export default function NewsListItem({
  news,
  prefix,
  contentClassName,
  perexClassName,
  titleClassName,
  timeClassName,
}: NewsListItemProps) {
  const publishedAt = fromUnixTime(news.publishedAt);

  const content = (
    <>
      <div className='NewsListItem__thumbnail'>
        {news.photoUrl && (
          <img className='NewsListItem__image' src={news.photoUrl} alt={news.title} />
        )}
        {news.union.id !== process.env.NEXT_PUBLIC_UNION && (
          <div className='NewsListItem__union'>{news.union.name}</div>
        )}
      </div>
      <div className={classNames('NewsListItem__content', contentClassName)}>
        <h3 className={classNames('NewsListItem__title', titleClassName)}>{news.title}</h3>
        <span className={classNames('NewsListItem__subtitle', timeClassName)}>
          <span className={timeClassName}>{formatDate(publishedAt, 'dd/MM/yyyy, HH:mm')}</span>
          {news.categories.map(category => {
            if (!category) {
              return null;
            }

            return (
              <span key={category.id} className={timeClassName}>
                {category.name}
              </span>
            );
          })}
        </span>
        <p className={classNames('NewsListItem__perex', perexClassName)}>
          {truncate(news.perex, 256)}
        </p>
      </div>
    </>
  );

  if (news.externalLink) {
    return (
      <a href={news.externalLink} target='_blank' className='NewsListItem' rel='noreferrer'>
        {content}
      </a>
    );
  }

  return (
    <Link href={`${prefix}/${news.paths.active}`}>
      <a className='NewsListItem'>{content}</a>
    </Link>
  );
}
