import React from 'react';
import Link from 'next/link';

import Button, { ButtonProps } from '../button/Button';
import { NewsListItemFragment } from '../../types/graphql';

import NewsListItem from './NewsListItem';

interface NewsPreviewListButtonProps {
  title: string;
  link: string;
  props: ButtonProps;
}

interface NewsPreviewListProps {
  title: string;
  news: NewsListItemFragment[];
  button: NewsPreviewListButtonProps;
}

function NewsPreviewList({ title, news, button }: NewsPreviewListProps) {
  return (
    <div className='NewsPreviewList'>
      <div className='NewsPreviewList__header'>
        <h2 className='h1'>{title}</h2>
        <Link href={button.link}>
          <a className='NewsPreviewList__headerLink'>
            <Button variant='blue' {...button.props}>
              {button.title}
            </Button>
          </a>
        </Link>
      </div>
      <div className='NewsPreviewList__items'>
        {news.map(item => (
          <NewsListItem key={item.id} news={item} prefix={button.link} />
        ))}
      </div>
      <Link href={button.link}>
        <a className='NewsPreviewList__link'>
          <Button variant='blue' {...button.props}>
            {button.title}
          </Button>
        </a>
      </Link>
    </div>
  );
}

export default NewsPreviewList;
