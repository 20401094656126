import {
  LeagueGroupFragment,
  LeagueGroupLabelFragment,
  SeasonFragment,
  SeasonLeagueFragment,
  SeasonLeaguePhaseFragment,
  TeamListItemFragment,
  TeamRetributionItemFragment,
} from '@amf/shared/types/graphql';
import { useTranslate } from '@tolgee/react';
import useLeagueGroups from '@amf/shared/data/hooks/useLeagueGroups';
import React, { ChangeEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import SelectField from '@amf/shared/components/form/SelectField';
import Link from 'next/link';
import classNames from 'classnames';
import Tippy from '@tippyjs/react';
import TeamAvatar from '@amf/shared/components/team/TeamAvatar';
import Button from '@amf/shared/components/button/Button';
import TeamRetribution from '@amf/shared/components/team/TeamRetribution';
import { LeagueLabelColor, LeaguePhaseType } from '@amf/shared/data/League';
import MatchPlayOffTable from '@amf/shared/components/match/MatchPlayOffTable';
import MatchPlayOffTableLoader from '@amf/shared/components/match/MatchPlayOffLoader';
import { formatDate } from '@amf/shared/utils/date';
import { PositionWithColor } from '@amf/shared/components/league/LeagueTeamTable';

type Retribution = {
  team: TeamListItemFragment;
  retribution: TeamRetributionItemFragment;
};

interface LeagueTableDashboardContentGroupProps {
  group: LeagueGroupFragment;
  prefixes: { team: string };
  color?: string;
}

function LeagueTableDashboardContentGroup({
  group,
  prefixes,
  color,
}: LeagueTableDashboardContentGroupProps) {
  const { t } = useTranslate();

  const retributions = useMemo<Retribution[]>(() => {
    const result: Retribution[] = [];
    for (const team of group.teams) {
      for (const retribution of team.retributions) {
        result.push({
          team: team.team,
          retribution,
        });
      }
    }
    return result;
  }, [group]);

  const useLegacyLabelSystem = useMemo(() => group.labels.length === 0, [group.labels]);
  const uniqueLabels = useMemo(
    () =>
      group.labels.reduce<LeagueGroupLabelFragment[]>((labels, label) => {
        const existingLabel = labels.find(
          ({ color, description }) => color === label.color && description === label.description,
        );

        if (Boolean(existingLabel) || !label.description) return labels;

        return [...labels, label];
      }, []),
    [group.labels],
  );

  const labelsByPosition = useMemo(
    () =>
      group.labels
        .map<PositionWithColor>(({ position, color }) => ({ position, color }))
        .reduce<PositionWithColor[]>((positions, position) => {
          const existingPosition = positions.find(p => p.position === position.position);
          if (Boolean(existingPosition)) return positions;
          return [...positions, position];
        }, []),
    [group.labels],
  );

  return (
    <div key={group.id}>
      <div className='LeagueTableDashboardGrid__table' style={{ borderColor: color }}>
        <div className='LeagueTableDashboardGrid__tableHeader' style={{ backgroundColor: color }}>
          <h3 className='LeagueTableDashboardGrid__tableHeaderName'>{group.name}</h3>
          <span className='LeagueTableDashboardGrid__tableHeaderType'>
            {t('league.table.points')}
          </span>
        </div>
        <div className='LeagueTableDashboardGrid__tableList'>
          {group.teams.map((team, index) => {
            const label = labelsByPosition.find(({ position }) => position === index + 1);
            return (
              <Link key={team.id} href={`${prefixes.team}/${team.id.split('||')[0]}`}>
                <a
                  className={classNames(
                    'LeagueTableDashboardGrid__tableTeam',
                    {
                      'LeagueTableDashboardGrid__tableTeam--champion':
                        useLegacyLabelSystem && group.champion && index === 0,
                      'LeagueTableDashboardGrid__tableTeam--advancing':
                        useLegacyLabelSystem && index < group.advancing,
                      'LeagueTableDashboardGrid__tableTeam--lastAdvancing':
                        useLegacyLabelSystem && index + 1 === group.advancing,
                      'LeagueTableDashboardGrid__tableTeam--firstDescending':
                        useLegacyLabelSystem && group.teams.length - index === group.descending,
                      'LeagueTableDashboardGrid__tableTeam--descending':
                        useLegacyLabelSystem && group.teams.length - index <= group.descending,
                    },
                    label ? `LeagueTableDashboardGrid__tableTeam--${label?.color}` : null,
                  )}
                >
                  {(label?.color === LeagueLabelColor.CHAMPION ||
                    (useLegacyLabelSystem && group.champion && index === 0)) && (
                    <div className='LeagueTableDashboardGrid__crosses'>
                      <div className='LeagueTableDashboardGrid__cross' />
                      <div className='LeagueTableDashboardGrid__cross' />
                      <div className='LeagueTableDashboardGrid__cross' />
                      <div className='LeagueTableDashboardGrid__cross' />
                      <div className='LeagueTableDashboardGrid__cross' />
                    </div>
                  )}
                  <span
                    className={classNames('LeagueTableDashboardGrid__tableNumber', {
                      'LeagueTableDashboardGrid__tableNumber--fit':
                        label?.color === LeagueLabelColor.CHAMPION ||
                        (useLegacyLabelSystem && group.champion && index === 0),
                    })}
                  >
                    {label?.color === LeagueLabelColor.CHAMPION ||
                    (useLegacyLabelSystem && group.champion && index === 0) ? (
                      <Tippy
                        content={t('league.table.champion')}
                        animation='shift-away'
                        theme='light'
                      >
                        <i className='icons-trophy LeagueTableDashboardGrid__tableNumber--yellow' />
                      </Tippy>
                    ) : (
                      <>{index + 1}</>
                    )}
                  </span>
                  <strong className='LeagueTableDashboardGrid__tableName'>
                    <TeamAvatar team={team.team} />
                    {team.team.name}
                  </strong>
                  <span className='LeagueTableDashboardGrid__tableInfo'>{team.totalPoints}</span>
                </a>
              </Link>
            );
          })}
        </div>
        {retributions.length > 0 && (
          <div className='LeagueTableDashboardGrid__tableRetributions'>
            {retributions.map(({ team, retribution }) => (
              <p key={retribution.id}>
                <i className='bi bi-info-circle-fill' />
                <TeamRetribution team={team} retribution={retribution} />
              </p>
            ))}
          </div>
        )}
      </div>
      {uniqueLabels && (
        <div className='LeagueTeamTable__labelList'>
          {uniqueLabels.map(label => (
            <p key={label.id} className='LeagueTeamTable__label'>
              <div
                className={classNames('LeagueTeamTable__labelColor', {
                  'LeagueTeamTable__labelColor--champion':
                    label.color === LeagueLabelColor.CHAMPION,
                  'LeagueTeamTable__labelColor--green': label.color === LeagueLabelColor.GREEN,
                  'LeagueTeamTable__labelColor--yellow': label.color === LeagueLabelColor.YELLOW,
                  'LeagueTeamTable__labelColor--orange': label.color === LeagueLabelColor.ORANGE,
                  'LeagueTeamTable__labelColor--red': label.color === LeagueLabelColor.RED,
                  'LeagueTeamTable__labelColor--gray': label.color === LeagueLabelColor.GRAY,
                })}
              >
                <span>{label.color}</span>
              </div>
              <span className='LeagueTeamTable__labelDescription'>{label.description}</span>
            </p>
          ))}
        </div>
      )}
    </div>
  );
}

interface LeagueTableDashboardContentProps {
  groups: LeagueGroupFragment[];
  prefixes: { team: string };
  colors?: string[];
}

function LeagueTableDashboardContent({
  groups,
  prefixes,
  colors,
}: LeagueTableDashboardContentProps) {
  return (
    <div
      className={classNames('LeagueTableDashboardGrid__content', {
        'LeagueTableDashboardGrid__content--1': groups.length === 1,
        'LeagueTableDashboardGrid__content--3': groups.length === 3,
      })}
    >
      {groups.map((group, key) => (
        <LeagueTableDashboardContentGroup
          key={group.id}
          group={group}
          prefixes={prefixes}
          color={colors ? colors[key] : undefined}
        />
      ))}
    </div>
  );
}

function LeagueTableDashboardLoader() {
  return (
    <div className='LeagueTableDashboardGrid__content LeagueTableDashboardGrid__content--loading'>
      {[...Array(2)].map((_, i) => (
        <div key={i} className='LeagueTableDashboardGrid__table'>
          <div className='LeagueTableDashboardGrid__tableHeader'>
            <h3 className='LeagueTableDashboardGrid__tableHeaderName' />
            <span className='LeagueTableDashboardGrid__tableHeaderType' />
          </div>
          <div className='LeagueTableDashboardGrid__tableList'>
            {[...Array(6)].map((_, x) => (
              <div key={x} className='LeagueTableDashboardGrid__tableTeam'>
                <span className='LeagueTableDashboardGrid__tableNumber' />
                <strong className='LeagueTableDashboardGrid__tableName'>
                  <div />
                </strong>
                <span className='LeagueTableDashboardGrid__tableInfo' />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

interface LeagueTableDashboardGridProps {
  season: SeasonFragment;
  prefixes: { team: string; table: string; match: string };
  colors?: string[];
}

function LeagueTableDashboardGrid({ season, prefixes, colors }: LeagueTableDashboardGridProps) {
  const { t } = useTranslate();

  const leagues = useMemo<SeasonLeagueFragment[]>(() => {
    return season.leagues.filter(league => league.phases.length > 0);
  }, [season]);

  const [league, setLeague] = useState<SeasonLeagueFragment>(leagues[0]);
  const [phase, setPhase] = useState<SeasonLeaguePhaseFragment | undefined>();
  const { groups, loading, updatedAt } = useLeagueGroups(league?.id, phase?.id);

  const changeLeague = useCallback<ChangeEventHandler<HTMLSelectElement>>(
    event => {
      const league = leagues.find(l => l.id === event.target.value);
      if (!league) return;
      setLeague(league);
    },
    [leagues],
  );

  const changePhase = useCallback<ChangeEventHandler<HTMLSelectElement>>(
    event => {
      const phase = league?.phases.find(p => p.id === event.target.value);
      setPhase(phase);
    },
    [league],
  );

  useEffect(() => {
    if (league?.phases.length === 0) return setPhase(undefined);
    const phase = league?.phases.find(phase => phase.current) ?? league?.phases[0];
    setPhase(phase);
  }, [league]);

  const type = phase?.type as LeaguePhaseType | undefined;
  if (leagues.length === 0) return null;
  return (
    <div className='LeagueTableDashboardGrid'>
      <div className='LeagueTableDashboardGrid__header'>
        <h2 className='h1 LeagueTableDashboardGrid__title'>{t('league.table.title')}</h2>
        <div className='LeagueTableDashboardGrid__actions'>
          <SelectField
            className='LeagueTableDashboardGrid__field'
            label={t('league.table.phase')}
            value={phase?.id}
            onChange={changePhase}
          >
            {league?.phases.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </SelectField>
          <SelectField
            className='LeagueTableDashboardGrid__field'
            label={t('league.table.league')}
            value={league?.id}
            onChange={changeLeague}
          >
            {leagues.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </SelectField>
        </div>
      </div>
      {type === LeaguePhaseType.table && phase && (
        <>
          {loading && <LeagueTableDashboardLoader />}
          {!loading && (
            <LeagueTableDashboardContent groups={groups} prefixes={prefixes} colors={colors} />
          )}
        </>
      )}
      {type !== LeaguePhaseType.table && phase && (
        <>
          {loading && <MatchPlayOffTableLoader />}
          {!loading && (
            <MatchPlayOffTable
              groups={groups}
              prefixes={prefixes}
              numberOfMutualMatches={phase.numberOfMutualMatches}
            />
          )}
        </>
      )}
      {updatedAt && !colors && (
        <p className='TeamTable__update'>
          Naposledy aktualizováno {formatDate(updatedAt, 'dd.MM.yyyy HH:mm')}.
        </p>
      )}
      <div className='LeagueTableDashboardGrid__more'>
        <Link href={prefixes.table}>
          <a>
            <Button variant='dark-outline'>{t('league.table.more')}</Button>
          </a>
        </Link>
      </div>
    </div>
  );
}

export default LeagueTableDashboardGrid;
