import classNames from 'classnames';
import { AdFragment } from '@amf/shared/types/graphql';
import { BentoGrid } from 'react-bento';
import { useMemo } from 'react';
import React from 'react';
import { BentoItem } from 'react-bento/dist/types/types';
import { useWindowSize } from 'usehooks-ts';

type Props = {
  wide: AdFragment[];
  video: AdFragment[];
  square: AdFragment[];
  squareSmall: AdFragment[];
  wideSmall: AdFragment[];
  tall: AdFragment[];
  className?: string;
};

type Size = 'sm' | 'md' | 'lg';

function Ads({ wide, video, square, wideSmall, squareSmall, tall, className }: Props) {
  const all = useMemo(
    () => [...wide, ...video, ...square, ...wideSmall, ...squareSmall, ...tall],
    [square, squareSmall, tall, video, wide, wideSmall],
  );

  const { width } = useWindowSize();
  const size = useMemo<Size>(() => {
    if (width > 800) return 'lg';
    if (width > 480) return 'md';
    return 'sm';
  }, [width]);

  const items = useMemo<BentoItem[]>(() => {
    return all.map(({ id, link, type, imageUrl }, key) => {
      let width: number = 1;
      let height: number = 1;

      switch (type) {
        case 'homepageWide':
          width = size === 'sm' ? 2 : size === 'md' ? 3 : 4;
          height = 1;
          break;

        case 'homepageWideSmall':
          width = size === 'sm' ? 1 : size === 'md' ? 2 : 2;
          height = 1;
          break;

        case 'homepageVideo':
          width = size === 'sm' ? 2 : size === 'md' ? 3 : 4;
          height = 2;
          break;

        case 'homepageSquare':
          width = size === 'sm' ? 2 : size === 'md' ? 2 : 2;
          height = 2;
          break;

        case 'homepageSquareSmall':
          width = 1;
          height = 1;
          break;

        case 'homepageTall':
          width = 1;
          height = 2;
          break;
      }

      return {
        id: key,
        title: type,
        element: (
          <a href={link} target='_blank' rel='noreferrer'>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img src={imageUrl} alt={link} unselectable={'on'} style={{ width: '100%' }} />
          </a>
        ),
        width,
        height,
      };
    });
  }, [all, size]);

  const arranged = useMemo<BentoItem[]>(
    () => arrangeBentoGrid(items, size === 'sm' ? 2 : size === 'md' ? 3 : 4, 100),
    [items, size],
  );

  return (
    // @ts-ignore
    <BentoGrid
      items={arranged}
      gridCols={size === 'sm' ? 2 : size === 'md' ? 3 : 4}
      rowHeight={size === 'sm' ? 140 : 278}
      classNames={{
        container: classNames('AdList', className),
        elementContainer: 'Ad',
      }}
    />
  );
}

export default Ads;

export function arrangeBentoGrid(
  tiles: BentoItem[],
  gridWidth: number,
  gridHeight: number,
): BentoItem[] {
  const sortedTiles = tiles.sort((a, b) => b.width * b.height - a.width * a.height);

  const grid: (BentoItem | null)[][] = Array.from({ length: gridHeight }, () =>
    Array(gridWidth).fill(null),
  );

  const positionedTiles: BentoItem[] = [];

  const canPlaceTile = (x: number, y: number, tile: BentoItem): boolean => {
    if (x + tile.width > gridWidth || y + tile.height > gridHeight) {
      return false;
    }
    for (let i = 0; i < tile.height; i++) {
      for (let j = 0; j < tile.width; j++) {
        if (grid[y + i][x + j] !== null) {
          return false;
        }
      }
    }
    return true;
  };

  const placeTile = (x: number, y: number, tile: BentoItem): void => {
    for (let i = 0; i < tile.height; i++) {
      for (let j = 0; j < tile.width; j++) {
        grid[y + i][x + j] = tile;
      }
    }
    positionedTiles.push(tile);
  };

  for (const tile of sortedTiles) {
    let placed = false;

    for (let y = 0; y < gridHeight; y++) {
      if (placed) break;

      for (let x = 0; x < gridWidth; x++) {
        if (canPlaceTile(x, y, tile)) {
          placeTile(x, y, tile);
          placed = true;
          break;
        }
      }
    }

    if (!placed) {
      console.warn(`Tile with id "${tile.id}" could not be placed.`);
    }
  }

  return positionedTiles;
}
