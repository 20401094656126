import {
  SeasonFragment,
  SeasonLeagueFragment,
  SeasonLeaguePhaseFragment,
} from '@amf/shared/types/graphql';
import { useTranslate } from '@tolgee/react';
import SelectField from '@amf/shared/components/form/SelectField';
import React, { ChangeEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import Link from 'next/link';
import ButtonIcon from '@amf/shared/components/button/ButtonIcon';
import MatchRoundTitle from '@amf/shared/components/match/MatchRoundTitle';
import { LeaguePhaseType } from '@amf/shared/data/League';
import Button from '@amf/shared/components/button/Button';
import useMatches from '@amf/shared/data/hooks/useMatches';
import MatchTable from '@amf/shared/components/match/MatchTable';

interface MatchDashboardListLeagueProps {
  leagues: SeasonLeagueFragment[];
  selected: SeasonLeagueFragment;
  onChange: (league: SeasonLeagueFragment) => void;
}

export function MatchDashboardListLeague({
  leagues,
  selected,
  onChange,
}: MatchDashboardListLeagueProps) {
  const { t } = useTranslate();
  const changeLeague = useCallback<ChangeEventHandler<HTMLSelectElement>>(
    event => {
      const league = leagues.find(l => l.id === event.target.value);
      if (!league) return;
      onChange(league);
    },
    [leagues, onChange],
  );

  if (leagues.length === 0) return null;
  return (
    <SelectField
      className='MatchDashboardList__field'
      label={t('match.list.league')}
      value={selected.id}
      onChange={changeLeague}
    >
      {leagues.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </SelectField>
  );
}

interface MatchDashboardListProps {
  season: SeasonFragment;
  prefixes: {
    team: string;
    match: string;
    matches: string;
  };
}

function MatchDashboardList({ season, prefixes }: MatchDashboardListProps) {
  const { t } = useTranslate();

  const leagues = useMemo<SeasonLeagueFragment[]>(() => {
    return season.leagues.filter(league => league.phases.length > 0);
  }, [season]);

  const [league, setLeague] = useState<SeasonLeagueFragment>(leagues[0]);
  const [phase, setPhase] = useState<SeasonLeaguePhaseFragment | undefined>();

  const [round, setRound] = useState<number>(1);
  useEffect(() => setRound(phase?.currentRound ?? 1), [phase]);

  const { matches, loading } = useMatches(season.id, league?.id ?? null, {
    phase: phase?.id,
    round,
  });

  const changeLeague = useCallback<ChangeEventHandler<HTMLSelectElement>>(
    event => {
      const league = leagues.find(l => l.id === event.target.value);
      if (!league) return;
      setLeague(league);
    },
    [leagues],
  );

  const changePhase = useCallback<ChangeEventHandler<HTMLSelectElement>>(
    event => {
      const phase = league?.phases.find(p => p.id === event.target.value);
      setPhase(phase);
    },
    [league],
  );

  useEffect(() => {
    if (league?.phases.length === 0) return setPhase(undefined);
    const phase = league?.phases.find(phase => phase.current) ?? league?.phases[0];
    setPhase(phase);
  }, [league]);

  if (leagues.length === 0 || !league) return null;
  return (
    <div className='MatchDashboardList'>
      <div className='MatchDashboardList__header'>
        <h2 className='h1 MatchDashboardList__title'>{t('match.list.title')}</h2>
        <div className='MatchDashboardList__actions'>
          <SelectField
            className='LeagueTableDashboardGrid__field'
            label={t('league.table.phase')}
            value={phase?.id}
            onChange={changePhase}
          >
            {league?.phases.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </SelectField>
          <SelectField
            className='LeagueTableDashboardGrid__field'
            label={t('league.table.league')}
            value={league?.id}
            onChange={changeLeague}
          >
            {leagues.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </SelectField>
        </div>
      </div>
      {phase && (
        <div className='MatchDashboardList__control'>
          {phase.lastMatchRound > 1 && (
            <ButtonIcon
              variant='dark'
              className='MatchDashboardList__button'
              disabled={round === 1}
              onClick={() => setRound(round - 1)}
            >
              <i className='bi bi-arrow-left-circle-fill' />
            </ButtonIcon>
          )}
          <span className='MatchDashboardList__round'>
            <MatchRoundTitle
              round={round}
              type={phase.type as LeaguePhaseType}
              max={phase.lastMatchRound}
              title={phase.name}
            />
          </span>
          {phase.lastMatchRound > 1 && (
            <ButtonIcon
              variant='dark'
              className='MatchDashboardList__button'
              disabled={round === phase.lastMatchRound}
              onClick={() => setRound(round + 1)}
            >
              <i className='bi bi-arrow-right-circle-fill' />
            </ButtonIcon>
          )}
        </div>
      )}
      <div className='MatchDashboardList__matches'>
        <MatchTable matches={matches} matchPrefix={prefixes.match} loading={loading} />
      </div>
      <div className='MatchDashboardList__more'>
        <Link href={prefixes.matches}>
          <a>
            <Button variant='dark-outline'>{t('match.list.more')}</Button>
          </a>
        </Link>
      </div>
    </div>
  );
}

export default MatchDashboardList;
