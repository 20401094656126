import useSWR from 'swr';
import { MatchListItemFragment } from '@amf/shared/types/graphql';

export const BASIC_MATCH_LIST_LIMIT = 20 as const;

export enum BasicMatchListType {
  future = 'future',
  past = 'past',
}

function useBasicMatchList(type: BasicMatchListType, apiPath: string = '/api/match/base') {
  const { data, ...other } = useSWR<MatchListItemFragment[]>(() => {
    const params = new URLSearchParams({ type });
    return `${apiPath}?${params.toString()}`;
  });

  return {
    matches: data ?? [],
    ...other,
  };
}

export default useBasicMatchList;
