/* eslint-disable @next/next/no-img-element */
import { useKeenSlider } from 'keen-slider/react';
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { BannerFragment } from '@amf/shared/types/graphql';
import Button, { ButtonProps } from '@amf/shared/components/button/Button';
import Container from '@amf/shared/components/layout/Container';

const SliderTransitionDelay = 10000;
const SliderTransitionSpeed = 640;

interface SliderProps {
  banners: BannerFragment[];
  buttonProps?: ButtonProps;
}

export default function Slider({ banners, buttonProps }: SliderProps) {
  const timer = useRef<number>();
  const [pause, setPause] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);
  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    duration: SliderTransitionSpeed,
    loop: true,
    dragStart: () => setPause(true),
    dragEnd: () => setPause(false),
    slideChanged() {
      setSlideIndex(-1);
    },
    afterChange(s) {
      const index = s.details().relativeSlide;
      setSlideIndex(index);
    },
  });

  useEffect(() => {
    if (banners.length > 1) {
      window.clearInterval(timer.current);
      timer.current = window.setInterval(() => {
        if (!pause && slider) {
          slider.next();
        }
      }, SliderTransitionDelay);
    }

    return () => {
      window.clearInterval(timer.current);
    };
  }, [slideIndex, pause, slider, banners]);

  return (
    <div className='Slider'>
      <div className='Slider__imageWrapper'>
        {banners.length > 0 && slideIndex >= 0 && banners[slideIndex].imageUrl && (
          <div
            className={classNames('Slider__image', {
              'Slider__image--blurred': banners[slideIndex].position !== 'center',
            })}
          >
            <img src={banners[slideIndex].imageUrl ?? undefined} alt='' />
          </div>
        )}
      </div>
      <div ref={sliderRef} className='Slider__content'>
        {banners.map(banner => (
          <div
            key={banner.id}
            className={classNames('Slider__hero', 'keen-slider__slide', {
              'Slider__hero--center': banner.position === 'center',
              'Slider__hero--right': banner.position === 'right',
            })}
          >
            <Container className='Slider__heroContainer'>
              <div className='Slider__heroInner'>
                <h1 className='Slider__heroTitle'>{banner.title}</h1>
                <p className='Slider__heroMessage'>{banner.message}</p>
                {banner.buttonTitle && banner.buttonLink && (
                  <a href={banner.buttonLink}>
                    <Button variant='blue' className='Slider__heroButton' {...buttonProps}>
                      {banner.buttonTitle}
                    </Button>
                  </a>
                )}
              </div>
              {banner.imageUrl && banner.position !== 'center' && (
                <img src={banner.imageUrl} alt={banner.title} className='Slider__heroImage' />
              )}
            </Container>
          </div>
        ))}
      </div>
      <Container className='Slider__panelContainer'>
        <div className='Slider__container'>
          <div className='Slider__panel'>
            {banners.map((banner, key) => (
              <div
                key={banner.id}
                onClick={() => slider.moveToSlide(key)}
                className={classNames('Slider__panelItem', {
                  'Slider__panelItem--active': key === slideIndex,
                })}
              >
                {banner.title}
              </div>
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
}
