import useSWR from 'swr';
import { LeagueGroupFragment } from '@amf/shared/types/graphql';
import { fromUnixTime } from 'date-fns';
import { Response } from '@amf/shared/data/api/Response';

const getCacheKey = (league: string | null, phase: string | null) => () => {
  if (!league) return null;
  const params = new URLSearchParams({ league });
  if (phase !== null) params.append('phase', phase);
  return `/api/league/groups?${params.toString()}`;
};

function useLeagueGroups(league: string | null = null, phase: string | null = null) {
  const { data, error } = useSWR<Response<LeagueGroupFragment[]>>(getCacheKey(league, phase));

  const groups = data?.data;
  return {
    error,
    groups: groups && Array.isArray(groups) ? groups : [],
    loading: !groups && !error,
    updatedAt: data?.updatedAt ? fromUnixTime(data.updatedAt) : null,
  };
}

export default useLeagueGroups;
