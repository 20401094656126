function MatchListItemLoading() {
  return (
    <div className='MatchListItem MatchListItem--loading'>
      <div className='MatchListItem__main' />
      <div className='MatchListItem__team MatchListItem__team--home'>
        <div className='MatchListItem__teamBrand' />
        <div className='MatchListItem__teamName' />
      </div>
      <div className='MatchListItem__time'>
        <span />
        <small />
      </div>
      <div className='MatchListItem__team MatchListItem__team'>
        <div className='MatchListItem__teamBrand' />
        <div className='MatchListItem__teamName' />
      </div>
      <div className='MatchListItem__info' />
    </div>
  );
}

export default MatchListItemLoading;
