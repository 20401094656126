import React from 'react';

function MatchPlayOffTableLoader() {
  return (
    <div className='MatchPlayOffTable'>
      <div className='MatchPlayOff__outer'>
        <div className='MatchPlayOff MatchPlayOff--loader'>
          <div className='MatchPlayOff__loader' />
          <div className='MatchPlayOff__loader' />
          <div className='MatchPlayOff__loader' />
        </div>
      </div>
    </div>
  );
}

export default MatchPlayOffTableLoader;
