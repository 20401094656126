import { MatchListItemFragment } from '@amf/shared/types/graphql';
import classNames from 'classnames';
import { useMatchRounds } from '@amf/shared/data/Match';
import MatchListItem from '@amf/shared/components/match/MatchListItem';
import { LeaguePhaseType } from '@amf/shared/data/League';
import MatchRoundTitle from '@amf/shared/components/match/MatchRoundTitle';
import { Fragment } from 'react';
import useMatchPhases from '@amf/shared/data/hooks/useMatchPhases';

import MatchListItemLoading from './MatchListItemLoading';

export interface MatchTableProps {
  matches: MatchListItemFragment[];
  matchPrefix: string;
  title?: string;
  center?: boolean;
  type?: LeaguePhaseType;
  noRounds?: boolean;
  loading?: boolean;
  phases?: boolean;
  phasesInRow?: boolean;
  leagueInRow?: boolean;
  disableAssists?: boolean;
  disablePoints?: boolean;
}

function MatchTable({
  matches,
  matchPrefix,
  title,
  center,
  type,
  noRounds,
  loading,
  phases: usePhases,
  phasesInRow: usePhasesInRow,
  leagueInRow: useLeagueInRow,
  disableAssists,
  disablePoints,
}: MatchTableProps) {
  const rounds = useMatchRounds(matches);
  const phases = useMatchPhases(usePhases ? matches : []);
  return (
    <div className='MatchTable'>
      {title && (
        <h2
          className={classNames('MatchTable__title', {
            'MatchTable__title--center': center,
          })}
        >
          {title}
        </h2>
      )}
      {loading && (
        <div className='MatchTable__list'>
          <MatchListItemLoading />
          <MatchListItemLoading />
          <MatchListItemLoading />
          <MatchListItemLoading />
        </div>
      )}
      {!loading && (
        <>
          {usePhases && !usePhasesInRow && !useLeagueInRow ? (
            <div className='MatchTable__list'>
              {phases.map(({ id, name, matches }) => (
                <div key={id}>
                  <div className='MatchTable__round'>{name}</div>
                  {matches.map(match => (
                    <MatchListItem
                      disableAssists={disableAssists}
                      disablePoints={disablePoints}
                      key={match.id}
                      match={match}
                      prefix={matchPrefix}
                      round={
                        rounds.length > 1 && (
                          <MatchRoundTitle
                            round={match.round}
                            type={type ?? LeaguePhaseType.table}
                            max={rounds.length}
                          />
                        )
                      }
                    />
                  ))}
                </div>
              ))}
            </div>
          ) : (
            <div className='MatchTable__list'>
              {noRounds &&
                !usePhasesInRow &&
                !useLeagueInRow &&
                matches.map(match => (
                  <MatchListItem
                    disableAssists={disableAssists}
                    disablePoints={disablePoints}
                    key={match.id}
                    match={match}
                    prefix={matchPrefix}
                    round={
                      rounds.length > 1 && (
                        <MatchRoundTitle
                          round={match.round}
                          type={type ?? LeaguePhaseType.table}
                          max={rounds.length}
                        />
                      )
                    }
                  />
                ))}
              {!noRounds &&
                !usePhasesInRow &&
                !useLeagueInRow &&
                rounds.map(({ round, matches }) => (
                  <Fragment key={round}>
                    {rounds.length > 1 && (
                      <div className='MatchTable__round'>
                        <MatchRoundTitle
                          round={round}
                          type={type ?? LeaguePhaseType.table}
                          max={rounds.length}
                        />
                      </div>
                    )}
                    {matches.map(match => (
                      <MatchListItem
                        key={match.id}
                        match={match}
                        prefix={matchPrefix}
                        disableAssists={disableAssists}
                        disablePoints={disablePoints}
                      />
                    ))}
                  </Fragment>
                ))}
              {usePhasesInRow &&
                matches.map(match => (
                  <MatchListItem
                    key={match.id}
                    match={match}
                    prefix={matchPrefix}
                    info={<span className='MatchTable__phaseName'>{match.phaseName}</span>}
                    disableAssists={disableAssists}
                    disablePoints={disablePoints}
                  />
                ))}
              {useLeagueInRow &&
                matches.map(match => (
                  <MatchListItem
                    key={match.id}
                    match={match}
                    prefix={matchPrefix}
                    info={<span className='MatchTable__phaseName'>{match.leagueName}</span>}
                    disableAssists={disableAssists}
                    disablePoints={disablePoints}
                  />
                ))}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default MatchTable;
