import useSWR from 'swr';
import { SeasonStatsFragment } from '@amf/shared/types/graphql';

const API_KEY = '/api/league/stats';
const getCacheKey = (season: string, league: string | null, limit: number | null) => () => {
  if (league === null) return null;
  const params = new URLSearchParams({ season, league });
  if (limit !== null) params.append('limit', `${limit}`);
  return `${API_KEY}?${params.toString()}`;
};

function useLeagueStats(season: string, league: string | null, limit: number | null = 5) {
  const { data, error } = useSWR<SeasonStatsFragment | undefined>(
    getCacheKey(season, league, limit),
  );
  return {
    error,
    stats: data,
    loading: !data && !error,
  };
}

export default useLeagueStats;
