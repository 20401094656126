import { useMemo } from 'react';
import { MatchListItemFragment } from '@amf/shared/types/graphql';

export type MatchPhase = {
  id: string;
  name: string;
  matches: MatchListItemFragment[];
};

function useMatchPhases(matches: MatchListItemFragment[]) {
  return useMemo<MatchPhase[]>(() => {
    let phases: MatchPhase[] = [];
    for (const match of matches) {
      const phase = phases.find(({ id }) => id === match.phaseId);
      if (phase) {
        phase.matches.push(match);
      } else {
        phases.push({ id: match.phaseId, name: match.phaseName, matches: [match] });
      }
    }
    return phases;
  }, [matches]);
}

export default useMatchPhases;
