import { useTranslate } from '@tolgee/react';
import { LeaguePhaseType } from '@amf/shared/data/League';

interface MatchRoundTitleProps {
  round: number;
  type: LeaguePhaseType;
  max: number;
  title?: string;
}

function MatchRoundTitle({ round, max, type, title }: MatchRoundTitleProps) {
  const { t } = useTranslate();

  if (max === 1 && title && type === 'custom') {
    return <>{title}</>;
  }

  if ((type !== 'playoff' && type !== 'advancedPlayoff') || !max) {
    return <>{t('match.table.round', { round })}</>;
  }

  const playOffRound = max - round + 1;
  return <>{t(`league.playoff.round.${playOffRound}`)}</>;
}

export default MatchRoundTitle;
