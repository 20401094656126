import { useTranslate } from '@tolgee/react';
import Slider from '@amf/shared/components/slider/Slider';
import Container from '@amf/shared/components/layout/Container';
import LeagueTableDashboardGrid from '@amf/shared/components/dashboard/LeagueTableDashboardGrid';
import MatchDashboardList from '@amf/shared/components/dashboard/MatchDashboardList';
import PlayerDashboardGrid from '@amf/shared/components/dashboard/PlayerDashboardGrid';
import NewsPreviewList from '@amf/shared/components/news/NewsPreviewList';
import {
  AdFragment,
  BannerFragment,
  NewsListItemFragment,
  SeasonFragment,
} from '@amf/shared/types/graphql';
import { ButtonProps } from '@amf/shared/components/button/Button';
import { GetStaticProps } from 'next';
import { sdk } from '@amf/shared/lib/graphQlClient';
import { getLanguageLocale } from '@amf/shared/utils/languages';
import { NavigationProps } from '@amf/shared/utils/navigation';
import React from 'react';
import Ads from '@amf/shared/components/ad/Ads';
import MatchBasicList from '@amf/shared/components/match/MatchBasicList';
import { BasicMatchListType } from '@amf/shared/data/hooks/useBasicMatchList';

type Props = {
  news: NewsListItemFragment[];
  banners: BannerFragment[];
  seasons: SeasonFragment[];
  ads: {
    wide: AdFragment[];
    video: AdFragment[];
    square: AdFragment[];
    squareSmall: AdFragment[];
    wideSmall: AdFragment[];
    tall: AdFragment[];
  };
  sliderButtonProps: ButtonProps;
  newsListButtonProps: ButtonProps;
  links: {
    team: string;
    table: string;
    match: string;
    matches: string;
    player: string;
    stats: string;
    newsList: string;
  };
  disablePoints?: boolean;
  enableNewMatchesListSystem?: boolean;
};

function HomePage({
  news,
  banners,
  seasons,
  ads,
  sliderButtonProps,
  newsListButtonProps,
  links,
  disablePoints,
  enableNewMatchesListSystem,
}: Props) {
  const { t } = useTranslate();
  return (
    <>
      <Slider banners={banners} buttonProps={sliderButtonProps} />
      <Container>
        {seasons.length > 0 && (
          <>
            <LeagueTableDashboardGrid
              season={seasons[0]}
              prefixes={{ team: links.team, table: links.table, match: links.match }}
            />
            {enableNewMatchesListSystem ? (
              <>
                <MatchBasicList
                  type={BasicMatchListType.future}
                  title={t('home.match.future.title')}
                  prefixes={{ match: links.match, matches: links.matches }}
                />
                <MatchBasicList
                  type={BasicMatchListType.past}
                  title={t('home.match.past.title')}
                  prefixes={{ match: links.match, matches: links.matches }}
                />
              </>
            ) : (
              <MatchDashboardList
                season={seasons[0]}
                prefixes={{ match: links.match, team: links.team, matches: links.matches }}
              />
            )}
            <PlayerDashboardGrid
              season={seasons[0]}
              prefixes={{ player: links.player, stats: links.stats }}
              disablePoints={disablePoints}
            />
          </>
        )}
        <Ads
          wide={ads.wide}
          video={ads.video}
          square={ads.square}
          wideSmall={ads.wideSmall}
          squareSmall={ads.squareSmall}
          tall={ads.tall}
        />
        <NewsPreviewList
          title={t('home.news.title')}
          news={news}
          button={{
            title: t('home.news.all'),
            link: links.newsList,
            props: newsListButtonProps,
          }}
        />
      </Container>
    </>
  );
}

export default HomePage;

export interface HomePageProps extends NavigationProps {
  news: NewsListItemFragment[];
  banners: BannerFragment[];
  seasons: SeasonFragment[];
  ads: {
    wide: AdFragment[];
    video: AdFragment[];
    square: AdFragment[];
    squareSmall: AdFragment[];
    wideSmall: AdFragment[];
    tall: AdFragment[];
  };
}

export const getStaticProps =
  (): GetStaticProps<HomePageProps> =>
  async ({ locale }) => {
    const { navigation } = await sdk.Navigation({
      union: process.env.NEXT_PUBLIC_UNION,
      locale: getLanguageLocale(locale!),
    });
    const { newsList: news } = await sdk.NewsList({
      union: process.env.NEXT_PUBLIC_UNION,
      offset: 0,
      limit: 4,
    });

    const { bannerList: banners } = await sdk.BannerList({ union: process.env.NEXT_PUBLIC_UNION });
    const { seasons } = await sdk.SeasonList({ union: process.env.NEXT_PUBLIC_UNION });

    const { wide, video, square, wideSmall, squareSmall, tall } = await sdk.AdAllList({
      union: process.env.NEXT_PUBLIC_UNION,
    });

    const { partnerList: partners } = await sdk.PartnerList({
      union: process.env.NEXT_PUBLIC_UNION,
    });

    return {
      props: {
        navigation,
        news,
        banners,
        seasons,
        ads: {
          wide,
          video,
          square,
          squareSmall,
          wideSmall,
          tall,
        },
        partners,
      },
      revalidate: 100,
    };
  };
