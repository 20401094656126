import React, { ReactNode, useState } from 'react';
import useBasicMatchList, {
  BASIC_MATCH_LIST_LIMIT,
  BasicMatchListType,
} from '@amf/shared/data/hooks/useBasicMatchList';
import { useTranslate } from '@tolgee/react';
import Button from '@amf/shared/components/button/Button';
import MatchTable from '@amf/shared/components/match/MatchTable';
import Link from 'next/link';

const PAGINATION_STEP = 5 as const;

type Props = {
  type: BasicMatchListType;
  title: ReactNode;
  prefixes: { match: string; matches: string };
};

function MatchBasicList({ type, title, prefixes }: Props) {
  const { t } = useTranslate();
  const { matches } = useBasicMatchList(type);
  const [limit, setLimit] = useState<number>(PAGINATION_STEP);

  if (matches.length === 0) return null;
  return (
    <div className='MatchDashboardList'>
      <div className='MatchDashboardList__header'>
        <h2 className='h1 MatchDashboardList__title'>{title}</h2>
      </div>
      <div className='MatchDashboardList__matches'>
        <MatchTable
          matches={matches.slice(0, limit)}
          matchPrefix={prefixes.match}
          noRounds
          disablePoints
          disableAssists
          leagueInRow
          center
        />
      </div>
      <div className='MatchDashboardList__more'>
        {limit >= BASIC_MATCH_LIST_LIMIT ? (
          <Link href={prefixes.matches} passHref>
            <a>
              <Button variant='dark-outline'>{t('home.match.list.showMatches')}</Button>
            </a>
          </Link>
        ) : (
          <Button variant='dark-outline' onClick={() => setLimit(limit + PAGINATION_STEP)}>
            {t('home.match.list.more')}
          </Button>
        )}
      </div>
    </div>
  );
}

export default MatchBasicList;
