import { useSWRInfinite } from 'swr';
import { MatchListItemFragment } from '@amf/shared/types/graphql';
import { getCacheKey, LIMIT } from '@amf/shared/data/Match';
import { useTranslate } from '@tolgee/react';
import { useCallback, useMemo } from 'react';
import Button from '@amf/shared/components/button/Button';

export type MatchSortOrder = 'round' | 'time';

export type MatchFilter = {
  phase?: string;
  team?: string;
  dateFrom?: string;
  dateTo?: string;
  round?: number;
  sortBy?: MatchSortOrder;
};

function useMatches(season: string, league: string | null, filter: MatchFilter | null = null) {
  const { t } = useTranslate();
  const { data, size, setSize, error } = useSWRInfinite<MatchListItemFragment[]>(
    getCacheKey({
      league,
      season,
      team: filter?.team ?? undefined,
      phase: filter?.phase ?? undefined,
      dateFrom: filter?.dateFrom ?? undefined,
      dateTo: filter?.dateTo ?? undefined,
      round: filter?.round ?? undefined,
      sortBy: filter?.sortBy ?? undefined,
    }),
  );

  const matches = useMemo<MatchListItemFragment[]>(
    () => (data ? ([] as MatchListItemFragment[]).concat(...data) : []),
    [data],
  );

  const loadMore = useCallback(() => {
    if (!matches || data === undefined) {
      return null;
    }

    if (size > data.length) {
      return (
        <Button type='button' variant='dark-outline' disabled>
          {t('match.loading')}
        </Button>
      );
    }

    if (LIMIT * size > matches.length) {
      return null;
    }

    return (
      <Button type='button' variant='dark-outline' onClick={() => setSize(size + 1)}>
        {t('match.more')}
      </Button>
    );
  }, [data, matches, setSize, size, t]);

  return {
    matches,
    loading: !data && !error,
    loadMore,
    setSize,
    size,
    loadingMore: data !== undefined && size > data.length,
  };
}

export default useMatches;
